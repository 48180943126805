import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from "./router";
import Cookies from 'js-cookie';
import i18n from './i18n'

Vue.use(ElementUI);

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  const token = Cookies.get('shecam-token-v2');
  if (to.path === '/login' && token) {
    next('/');
  } else {
    next()
  }
})

new Vue({
  router,
  i18n,
  render: h => h(App),
}).$mount('#app')
