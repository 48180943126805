import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
  en: {
    btns: {
      tryit: 'Try it',
      changepwd: 'Change Password',
      logout: 'Logout',
    },
    message: {
    }
  },
  zh: {
    btns: {
      tryit: '立即体验',
      changepwd: '修改密码',
      logout: '退出登录',
    },
    message: {
    }
  }
};

const i18n = new VueI18n({
  locale: 'zh', // set default locale
  messages, // set locale messages
});

export default i18n;
