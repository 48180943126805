<template>
  <el-container class="playout">
    <el-main>
      <router-view></router-view>
    </el-main>
    <!-- v-if="this.$router.currentRoute.fullPath != '/video_gen'" -->
    <el-footer   style="height:auto; " class="playout-footer">
<!--      <el-row >-->
<!--        <el-col :span="isMobile ? 24 : 12" class="align-left">-->
<!--          <img src="@/assets/layout/footer-background.svg" width="100%" height="100%">-->
<!--        </el-col>-->
<!--        <el-col :span="isMobile ? 8 : 4">-->
<!--          <div class="card-header">-->
<!--            产品功能-->
<!--          </div>-->
<!--          <div v-for="item in 5" :key="item" class="card-item">-->
<!--            {{ '- 产品功能' + item }}-->
<!--          </div>-->
<!--        </el-col>-->
<!--        <el-col :span="isMobile ? 8 : 4">-->
<!--          <div class="card-header">-->
<!--            产品方案-->
<!--          </div>-->
<!--          <div v-for="item in 6" :key="item" class="card-item">-->
<!--            {{ '- 产品方案' + item }}-->
<!--          </div>-->
<!--        </el-col>-->
<!--        <el-col :span="isMobile ? 8 : 4">-->
<!--          <div class="card-header">-->
<!--            联系方式-->
<!--          </div>-->
<!--          <div v-for="item in 3" :key="item" class="card-item">-->
<!--            {{ '- 联系方式' + item }}-->
<!--          </div>-->
<!--        </el-col>-->
<!--      </el-row>-->
      <el-divider></el-divider>
      <el-row>
        <el-col :span="isMobile ? 24 : 12" class="align-left copyright">
          <p class="copyright-text">©2024 Visual π</p>
<!--          <p class="copyright-text">京公网安备xxx号</p>-->
          <p class="copyright-text"><a href="https://beian.miit.gov.cn"  style="color:#999;text-decoration: none;" target="_blank">京ICP备2024071827号-1</a></p>
        </el-col>
        <el-col :span="isMobile ? 24 : 12" class="align-right align-center" style="justify-content: right;">
          <span class="tran-earth">
            <img src="@/assets/layout/earth.svg">
          </span>
          <span class="tran-select">
            <el-dropdown trigger="click" @command="handleLanguage">
              <span class="el-dropdown-link">{{ langOpt }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="zh">中文</el-dropdown-item>
                <el-dropdown-item command="en">English</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <el-select v-model="langOpt">
              <el-option v-for="item in langOptions" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select> -->
          </span>
        </el-col>
      </el-row>
    </el-footer>
  </el-container>
</template>

<script>
import { isMobile } from '@/utils/client'

export default {
  data() {
    return {
      langOpt: '中文',
      // langOptions: [
      //   {
      //     value: 'zh',
      //     label: '中文'
      //   }, {
      //     value: 'en',
      //     label: 'English'
      //   }
      // ]
    }
  },
  computed: {
    isMobile() {
      return isMobile();
    }
  },
  methods: {
    handleLanguage(cmd) {
      this.$i18n.locale = cmd;
      if (cmd == 'zh') {
        this.langOpt = '中文';
      } else if(cmd == 'en') {
        this.langOpt = 'English';
      }
    }
  }
}

</script>

<style scoped>
.playout{
  height: 100%;
  max-width: 960px;

  display: block;
}



.el-header {}

.el-main {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.el-footer {}

.el-row {
  width: 100%;
}

.el-divider {
  background-color: #999;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.card-header {
  color: #fff;
  font-size: 14px;
  padding: 18px;
  text-align: left;
}

.card-item {
  padding: 12px;
  color: #999;
  font-size: 12px;
  text-align: left;
}

.copyright {
  color: #999;
  font-size: 10px;
}

.copyright-text {
  display: inline-block;
  margin-right: 40px;
}

.tran-earth {
  display: inline-block;
  height: 24px;
}

.tran-select {
  display: inline-block;
  height: 24px;
  margin-left: 12px;
}

.el-dropdown {
  color: #fff;
}

/* ::v-deep .el-input__inner {
  background: #000;
  color: #fff;
  border: #000;
  font-size: 14px;
  font-weight: bold;
  width: 100px;

  &:hover {
    border: #000;
  }
} */
</style>
